<template>
    <div class="container">
        <div class="head">
            完善认证信息
        </div>
        <div class="register-box">
            <div class="register-container">
                <auth-edit ref="authEdit" :editType="2" :userId="userId" :id="id" :authType="authType" :step1Data="step1Data" ></auth-edit>
                <div class="btns MT20">
                    <!-- <el-button @click="goBack">上一步</el-button> -->
                    <el-button type="primary" @click="onSubmit" :loading="btnLoading">提交审核</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthEdit from '@/components/AuthEdit'
export default {
    components:{
        AuthEdit
    },
    data(){
        return{
            btnLoading:false,
            userId: this.$route.query.userId || '',
            authType: this.$route.query.authType || 2, //1：认领，2：认证
            userType: this.$route.query.userType,
			id:this.$route.query.id || '',
			step1Data:this.$route.query.step1Data
        }
    },
    methods:{
        goBack(){
            this.$router.push({
                path: '/register/step1',
                query: {
                    userId: this.userId,
                    authType: this.authType,
                    userType: this.userType,
					id: this.id
                }
            });
        },
        async onSubmit(){
            this.btnLoading = true;
            await this.$refs.authEdit.onSubmit();
            this.btnLoading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import './step.scss';
</style>